<template>
<div id="topNav">
  <!-- Mobile Menu -->
    <div class="menu MobileMenu">
      <div id="nav" style="z-index:10;">
        <b-navbar toggleable class="nopadNav">
          <b-container>
            <b-navbar-brand href="/">
              <img class="siteLogo" src="https://updated.sunshinetour.com//wp-content/uploads/2021/04/q-school-visit-sa-open-12.png">
            </b-navbar-brand>
            <div class="RightMenuBlock testCLass">
              <b-button v-b-toggle.sidebar-1>
                <img class="menuWidth" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/Menu_White.png">
              </b-button>
            </div>
            <b-alert
              class="AlertNot"
              v-if="log.status === 'OK'"
              :show="dismissCountDown"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
            >
              Welcome {{ log.playerName }}
            </b-alert>
            <b-alert
              class="AlertNot"
              v-if="log.status === 'FAILED'"
              :show="dismissCountDown"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
            >
              Details are incorrect. Please try again.
            </b-alert>
          </b-container>
          <b-sidebar id="sidebar-1" shadow right>
            <div class="px-3 py-2">
              <b-navbar-nav class="ml-auto menuLeftTab">
                <template v-if="reports.report_title  === 'Tournament Entries'">
                  <b-nav-item
                    :to="{
                      name: 'reports',
                      query: {
                        url:
                          'https://sunshinetour.info/tic/tmentry.cgi?tourn=' +
                          course +
                          '~season=' +
                          seasons +
                          '~alphaorder~',
                        id: seasons,
                        code: course,
                        title: 'Tournament Entries',
                      },
                    }">Leaderboard
                  </b-nav-item>
                </template>
                <template v-else 
                >
                  <b-nav-item
                    :to="{
                      name: 'schedule',
                      query: {
                        id: seasons,
                        code: course,
                      },
                    }">
                    Leaderboard
                  </b-nav-item>
                </template>
                <b-nav-item :to="{
                  name: 'schedule',
                  query: {
                    id: seasons,
                    code: course,
                  },
                }">Schedule</b-nav-item>
                <b-nav-item :to="{
                  name: 'players',
                  query: {
                    id: seasons,
                  },
                }">Players</b-nav-item>
                <b-nav-item to="/news">News</b-nav-item>
                <b-nav-item :to="{
                  name: 'oom',
                  query: {
                    id: lastseason,
                  },
                }">Stats</b-nav-item>
                <b-nav-item to="/media-accreditation-form">Media Accreditation Form</b-nav-item>
                <b-nav-item href="/partners-and-sponsors">Partners & Sponsors</b-nav-item>
                <b-nav-item to="/the-papwa-swegolum-class">The Papwa Sewgolum Class</b-nav-item>
                <!-- <b-nav-item href="/tournament?id=224S&code=STQF">Q-School</b-nav-item> -->
                <b-nav-item :to="{
                  name: 'tournament',
                  query: {
                    id: seasons,
                    code: 'STQF',
                  },
                }">Q-School</b-nav-item>
                <!-- <b-nav-item href="#">FAN CLUB</b-nav-item>
                <b-nav-item href="#">THE TOUR</b-nav-item> -->
                <b-nav-item :to="{
                  name: 'tournament',
                  query: {
                    id: seasons,
                    code: 'VTRS',
                  },
                }">Vusi Ngubeni</b-nav-item>
                <b-nav-item to="/contact-us">Contact Us</b-nav-item>
                <b-nav-item to="/tickets">Buy Tickets</b-nav-item>
                <!-- <div class="playerSignin">
                  <span class="signInplayer" v-b-modal.modal-1 v-if="log === '' || log.status === null || log.status === 'FAILED'">
                    <b-icon icon="person-fill"></b-icon> Player Sign In
                  </span>
                  <span class="signInplayer" v-if="log.status === 'OK'" @click='signOut'>
                    <font-awesome-icon class="lock" :icon="['fa', 'golf-ball']" /> Logout
                  </span>
                </div> -->
              </b-navbar-nav>
              <b-navbar-nav class="ml-auto">
                <b-row>
                  <b-col lg="12" md="12" cols="12" class="bigEasyback">
                    <a class="sstNOunderliner" href="https://bigeasytour.co.za/" target="_blank">
                      <p class="sunshineTitle">SUNSHINE</p>
                      <p class="sunshineTour">BIG EASY TOUR</p>
                    </a>
                  </b-col>
                  <b-col lg="12" md="12" cols="12" class="ladiesTourback">
                    <a class="sstNOunderliner" href="https://sunshineladiestour.golf/" target="_blank">
                      <p class="sunshineTitle">SUNSHINE</p>
                      <p class="sunshineTour">LADIES TOUR</p>
                    </a>
                  </b-col>
                  <b-col lg="12" md="12" cols="12" class="seniorback">
                    <a class="sstNOunderliner" href="https://seniortour.co.za/" target="_blank">
                      <p class="sunshineTitle">SUNSHINE</p>
                      <p class="sunshineTour">SENIOR TOUR</p>
                    </a>
                  </b-col>
                </b-row>
              </b-navbar-nav>
              <b-navbar-nav class="ml-auto">
                <div class="coSantioned">Co-Sanctioned Events</div>
                <b-row>
                  <b-col v-for="(coSan, index) in co_sanctioned_events" :key="index" v-show="coSan.acf.show_co_sanctioned_event == true" lg="3" md="12" cols="12" class="backImage-Co" v-bind:style="{ backgroundImage: 'url(' + coSan.acf.course_image + ')' }">
                    <b-link class="sstNOunderliner" :to="coSan.acf.course_link">
                      <b-img class="courseLogo-co" :src="coSan.acf.course_logo"></b-img>
                    </b-link>
                  </b-col>
                </b-row>
                <b-row style="display:none">
                  <b-col lg="3" md="12" cols="12" class="backImage-Co" v-bind:style="{ backgroundImage: 'url(' + co_sanctioned_events[0].acf.course_image + ')' }">
                    <b-link class="sstNOunderliner" :to="co_sanctioned_events[0].acf.course_link">
                      <b-img class="courseLogo-co" :src="co_sanctioned_events[0].acf.course_logo"></b-img>
                    </b-link>
                  </b-col>
                  <b-col lg="3" md="12" cols="12" class="backImage-Co" v-bind:style="{ backgroundImage: 'url(' + co_sanctioned_events[1].acf.course_image + ')' }">
                    <b-link class="sstNOunderliner" :to="co_sanctioned_events[1].acf.course_link">
                      <b-img class="courseLogo-co" :src="co_sanctioned_events[1].acf.course_logo"></b-img>
                    </b-link>
                  </b-col>
                  <b-col lg="3" md="12" cols="12" class="backImage-Co" v-bind:style="{ backgroundImage: 'url(' + co_sanctioned_events[2].acf.course_image + ')' }">
                    <b-link class="sstNOunderliner" :to="co_sanctioned_events[2].acf.course_link">
                      <b-img class="thirdCourse-logo" :src="co_sanctioned_events[2].acf.course_logo"></b-img>
                    </b-link>
                  </b-col>
                  <b-col lg="3" md="12" cols="12" class="backImage-Co" v-bind:style="{ backgroundImage: 'url(' + co_sanctioned_events[3].acf.course_image + ')' }">
                    <b-link class="sstNOunderliner" :to="co_sanctioned_events[3].acf.course_link">
                      <b-img class="fourthCourse-logo" :src="co_sanctioned_events[3].acf.course_logo"></b-img>
                    </b-link>
                  </b-col>
                </b-row>
              </b-navbar-nav>
            </div>
          </b-sidebar>
        </b-navbar>
      </div>
    </div>
  <!-- End of Mobile Menu -->
  <!-- Desktop Menu -->
    <div class="menu DesktopMenu" :class="{fixedHead : fixedHead == true}">
      <div id="nav" style="z-index:10;">
        <b-navbar toggleable class="nopadNav">
          <b-container>
            <b-navbar-brand href="/">
              <img class="siteLogo" src="https://updated.sunshinetour.com//wp-content/uploads/2021/04/q-school-visit-sa-open-12.png">
            </b-navbar-brand>
            <!-- <b-link :to="'/tickets'">Buy Tickets Test</b-link> -->
            <span class="RightMenuBlock">
              <span>
                <b-navbar-toggle target="navbar-toggle-collapse-tour-co">
                  <div class="">
                    <span class="ourTours">
                      Co-Sanctioned Events
                    </span>
                  </div>
                </b-navbar-toggle>
                <b-navbar-toggle target="navbar-toggle-collapse-tour-co">
                  <template #default="{ expanded }">
                    <div v-if="expanded">
                      <img class="menuWidthTour" src="https://updated.sunshinetour.com/wp-content/uploads/2022/09/UP_Arrow_Whitex.png">
                    </div>
                    <div v-else>
                      <img class="menuWidthTour" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/Down_Arrow_White.png">
                    </div>
                  </template>
                </b-navbar-toggle>
              </span>
              <span>
                <b-navbar-toggle target="navbar-toggle-collapse-tour">
                  <div class="">
                    <span class="ourTours">
                      Our Tours
                    </span>
                  </div>
                </b-navbar-toggle>
                <b-navbar-toggle target="navbar-toggle-collapse-tour">
                  <template #default="{ expanded }">
                    <div v-if="expanded">
                      <img class="menuWidthTour" src="https://updated.sunshinetour.com/wp-content/uploads/2022/09/UP_Arrow_Whitex.png">
                    </div>
                    <div v-else>
                      <img class="menuWidthTour" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/Down_Arrow_White.png">
                    </div>
                  </template>
                </b-navbar-toggle>
              </span>

              <!-- <div class="playerSignin" :class="{playerSignInSroll : fixedHead == true}">
                <span class="signInplayer">
                  <b-nav-item :class="{playerSignInSroll : fixedHead == true}" class="" v-b-modal.modal-1 v-if="log === '' || log.status === null || log.status === 'FAILED'">
                    Player Sign In
                    <font-awesome-icon class="lock" :icon="['fa', 'lock']" />
                  </b-nav-item>
                  <b-nav-item class="" v-if="log.status === 'OK'" @click='signOut'>
                    Sign Out
                    <font-awesome-icon class="lock" :icon="['fa', 'golf-ball']" />
                  </b-nav-item>
                </span>
              </div> -->
              
              <template v-if="reg_allow_reg == 'B' || reg_allow_reg == 'W'">
                <template v-if="check == '0' && loggedInFan == 'N'">
                  <div class="buyTicketsBlock  " >
                    <b-nav-item @click="postFanselect()" class="BuyTickets" style="color:#fff!important"
                      v-b-modal.modal-lg>
                      Fan Zone
                    </b-nav-item>
                  </div>
                </template>
                <template v-else>
                  <div class="buyTicketsBlock" >
                    <b-nav-item :to="'/fanZone'" class="BuyTickets" style="color:#fff!important"
                      >
                      Fan Zone Page..
                    </b-nav-item>
                  </div>
                </template>
              </template>
              <template v-else>
                <div class="buyTicketsBlock">
                  <b-nav-item class="BuyTickets" style="color:#fff!important"
                    :to="{
                      name: 'tickets',
                    }">
                    Buy Tickets
                  </b-nav-item>
                </div>
              </template>


              <!-- <template>
                <template v-if="check == '0' && loggedInFan == 'N'">
                  <div class="buyTicketsBlock" >
                    <b-nav-item @click="postFanselect()" class="BuyTickets" style="color:#fff!important"
                      v-b-modal.modal-lg>
                      Fan Zone
                    </b-nav-item>
                  </div>
                </template>
                <template v-else>
                  <div class="buyTicketsBlock" >
                    <b-nav-item :to="'/fanZone'" class="BuyTickets" style="color:#fff!important"
                      >
                      Fan Zone Page
                    </b-nav-item>
                  </div>
                </template>
              </template> -->

                <!-- two -->
                <template v-if="check == '1' || loggedInFan == 'Y'">
                </template>
                <template v-else>
                  <FanzoneModal 
                    @Authorisied="checkboxValue"
                    @LoggedIn="checkLoggedIn"
                    :initalFanData='initalFanData' 
                    :latestTerms='latestTerms'
                    :latestTermsID="latestTermsID"
                    :showTermsBefore="showTermsBefore"
                    :selectOpts='selectOpts'
                    :config='config'
                  />
                </template>
              <!-- </template>              -->
               <!-- End of Fan zone Modal -->
              <b-modal
                class="modalCLass"
                id="modal-1"
                title="Sunshine Tour Player Login"
                centered
                hide-footer
              >
                <b-form @submit.prevent="onSubmit" class="modalCLass">
                  <b-form-group
                    id="input-group-2"
                    label="Your Name:"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="form.user"
                      placeholder="Enter User Name"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="input-group-3"
                    label="Your Password:"
                    label-for="input-3"
                  >
                    <b-form-input
                      id="input-3"
                      type='password'
                      v-model="form.password"
                      placeholder="Enter Password"
                      required
                    ></b-form-input> 
                  </b-form-group>

                  <b-button type="submit" variant="primary" class="logIN">Log In</b-button>
                </b-form>
              </b-modal>
              <b-alert
                class="AlertNot"
                v-if="log.status === 'OK'"
                :show="dismissCountDown"
                dismissible
                variant="success"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
              >
                Welcome {{ log.playerName }}
              </b-alert>
              <b-alert
                class="AlertNot"
                v-if="log.status === 'FAILED'"
                :show="dismissCountDown"
                dismissible
                variant="danger"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
              >
                Details are incorrect. Please try again.
              </b-alert>

              <b-navbar-toggle target="navbar-toggle-collapseMenu" class="btnWidth">
                <template #default="{ expanded }">
                  <div v-if="expanded" style="color:#fff" >
                    <!-- Close -->
                    <img class="menuWidth" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/Close_White.png">
                  </div>
                  <div v-else style="color:#fff">
                    <!-- Open -->
                    <img class="menuWidth" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/Menu_White.png">
                  </div>
                </template>
              </b-navbar-toggle>
            </span>
          </b-container>
          <b-collapse id="navbar-toggle-collapse-tour" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-row>
                <b-col lg="4" md="12" cols="12" class="bigEasyback">
                  <a class="sstNOunderliner" href="https://bigeasytour.co.za/" target="_blank">
                    <!-- <p class="sunshineTitle">SUNSHINE</p>
                    <p class="sunshineTour">BIG EASY TOUR</p> -->
                    <img class="tourImageNav" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2024/06/fourthImage.png" style="width: 250px; margin-top: 15x;">
                  </a>
                </b-col>
                <b-col lg="4" md="12" cols="12" class="ladiesTourback">
                  <a class="sstNOunderliner" href="https://sunshineladiestour.golf/" target="_blank">
                    <!-- <p class="sunshineTitle">SUNSHINE</p>
                    <p class="sunshineTour">LADIES TOUR</p> -->
                    <img class="tourImageNav" style="width: 200px; margin-top: -5px;" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2023/01/secondLDSLogo.png">
                  </a>
                </b-col>
                <b-col lg="4" md="12" cols="12" class="seniorback">
                  <a class="sstNOunderliner" href="https://seniortour.co.za/" target="_blank">
                    <!-- <p class="sunshineTitle">SUNSHINE</p>
                    <p class="sunshineTour">SENIOR TOUR</p> -->
                    <img class="tourImageNav" src="https://updated.sunshinetour.com//wp-content/uploads/2021/04/q-school-visit-sa-open-4.png">
                  </a>
                </b-col>
              </b-row>
            </b-navbar-nav>
          </b-collapse>
          <b-collapse id="navbar-toggle-collapse-tour-co" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-row>
                <b-col v-for="(coSan, index) in co_sanctioned_events" :key="index" v-show="coSan.acf.show_co_sanctioned_event == true" lg="3" md="12" cols="12" class="backImage-Co" v-bind:style="{ backgroundImage: 'url(' + coSan.acf.course_image + ')' }">
                  <b-link class="sstNOunderliner" :to="coSan.acf.course_link">
                    <b-img class="courseLogo-co" :src="coSan.acf.course_logo"></b-img>
                  </b-link>
                </b-col>
              </b-row>
              <b-row style="display:none">
                <b-col lg="3" md="12" cols="12" class="backImage-Co" v-bind:style="{ backgroundImage: 'url(' + co_sanctioned_events[0].acf.course_image + ')' }">
                  <b-link class="sstNOunderliner" :to="co_sanctioned_events[0].acf.course_link">
                    <b-img class="courseLogo-co" :src="co_sanctioned_events[0].acf.course_logo"></b-img>
                  </b-link>
                </b-col>
                <b-col lg="3" md="12" cols="12" class="backImage-Co" v-bind:style="{ backgroundImage: 'url(' + co_sanctioned_events[1].acf.course_image + ')' }">
                  <b-link class="sstNOunderliner" :to="co_sanctioned_events[1].acf.course_link">
                    <b-img class="courseLogo-co" :src="co_sanctioned_events[1].acf.course_logo"></b-img>
                  </b-link>
                </b-col>
                <b-col lg="3" md="12" cols="12" class="backImage-Co" v-bind:style="{ backgroundImage: 'url(' + co_sanctioned_events[2].acf.course_image + ')' }">
                  <b-link class="sstNOunderliner" :to="co_sanctioned_events[2].acf.course_link">
                    <b-img class="thirdCourse-logo" :src="co_sanctioned_events[2].acf.course_logo"></b-img>
                  </b-link>
                </b-col>
                <b-col lg="3" md="12" cols="12" class="backImage-Co" v-bind:style="{ backgroundImage: 'url(' + co_sanctioned_events[3].acf.course_image + ')' }">
                  <b-link class="sstNOunderliner" :to="co_sanctioned_events[3].acf.course_link">
                    <b-img class="fourthCourse-logo" :src="co_sanctioned_events[3].acf.course_logo"></b-img>
                  </b-link>
                </b-col>
              </b-row>
            </b-navbar-nav>
          </b-collapse>
          <b-collapse id="navbar-toggle-collapseMenu" is-nav class="menuDrop">
            <b-container class="containerMenuBlock">
              <b-navbar-nav class="ml-auto menuLeftTab">
                <template v-if="reports.report_title  === 'Tournament Entries'">
                  <b-nav-item
                    :to="{
                      name: 'reports',
                      query: {
                        url:
                          'https://sunshinetour.info/tic/tmentry.cgi?tourn=' +
                          course +
                          '~season=' +
                          seasons +
                          '~alphaorder~',
                        id: seasons,
                        code: course,
                        title: 'Tournament Entries',
                      },
                    }">Leaderboard
                  </b-nav-item>
                </template>
                <template v-else 
                >
                  <b-nav-item
                    :to="{
                      name: 'schedule',
                      query: {
                        id: seasons,
                        code: course,
                      },
                    }">
                    Leaderboard
                  </b-nav-item>
                </template>
                <b-nav-item :to="{
                  name: 'schedule',
                  query: {
                    id: seasons,
                    code: course,
                  },
                }">Schedule</b-nav-item>
                <b-nav-item :to="{
                  name: 'players',
                  query: {
                    id: seasons,
                  },
                }">Players</b-nav-item>
              </b-navbar-nav>
              <b-navbar-nav class="ml-auto menuLeftTab">
                <b-nav-item to="/media-accreditation-form">Media Accreditation Form</b-nav-item>
                <b-nav-item to="/news">News</b-nav-item>
                <b-nav-item :to="{
                  name: 'oom',
                  query: {
                    id: lastseason,
                  },
                }">Stats</b-nav-item>
              </b-navbar-nav>
              <b-navbar-nav class="ml-auto menuLeftTab thirdRow">
                <b-nav-item :to="{
                  name: 'tournament',
                  query: {
                    id: seasons,
                    code: 'STQF',
                  },
                }">Q-School</b-nav-item>
                <!-- <b-nav-item href="#">FAN CLUB</b-nav-item>
                <b-nav-item href="#">THE TOUR</b-nav-item> -->
                <b-nav-item to="/partners-and-sponsors">Partners & Sponsors</b-nav-item>
                <b-nav-item to="/the-papwa-swegolum-class">The Papwa Sewgolum Class</b-nav-item>
              </b-navbar-nav>
              <b-navbar-nav class="ml-auto menuLeftTab fourthRow">
                <!-- <b-nav-item href="https://updated.sunshinetour.com/q-school-visit-sa-open/">Q-SCHOOL</b-nav-item> -->
                <b-nav-item to="/contact-us">Contact Us</b-nav-item>
                <b-nav-item :to="{
                  name: 'tournament',
                  query: {
                    id: seasons,
                    code: 'VTRS',
                  },
                }">Vusi Ngubeni</b-nav-item>
                <b-nav-item to="/sa-open">SA-Open</b-nav-item>
                <!-- <b-nav-item href="#">FAN CLUB</b-nav-item> -->
              </b-navbar-nav>
            </b-container>
          </b-collapse>  
        </b-navbar>
      </div>
    </div>
  <!-- End of Desktop Menu -->
</div>

</template>

<script>
import axios from "axios";
import apiCall from "@/services/api.js";
import socketServe from "@/js/socketio-services.js";
import FanzoneModal from "@/ocs/fanzone/components/fanzoneModal.vue"
export default {
  name: "Menu",
  props: ["foot", "config"],
  components: {
    FanzoneModal
  },
  data() {
    return {
      // elementVisible: true,
      lastseason: '223S',
      check: false,
      loggedInFan: 'N',
      scrollPosition: null,
      fixedHead: false,
      menueLink: "menuLink",
      seasons: [],
      course: [],
      reports: [],
      isHidden: true,
      text: "",
      show: true,
      hover: false,
      about: [],
      co_sanctioned_events:[],
      form: {
        user: "",
        password: "",
        firstname: "",
        lastname: "",
        email: ""
      },
      selectOpts: {},
      log:'',
        dismissSecs: 5,
        dismissCountDown: 0,
      initalFanData: [],
      latestTerms: [],
      reg_allow_reg: '',
    };
  },
  computed: {
    filterReports: function() {
      if (Array.isArray(this.reports)) {
        return this.reports.filter(
          (reports_entry) =>
            reports_entry.report_title.includes("Tournament Entries") ||
            reports_entry.report_title.includes("Score") ||
            // reports_entry.report_title.includes("Live") ||
            reports_entry.report_title.includes("Final")
        );
      } else return this.reports; 
    },
    currentReport: function() {
      if (Array.isArray(this.filterReports))
        return this.filterReports[this.filterReports.length - 1].report_url;
      else return this.filterReports.report_url;
    },
    currentReportNav: function () {
      return this.reports.filter(
        value => value.report_url.includes('tmscores') || 
        value.report_url.includes('tmresult') ||
        value.report_url.includes('tmentry')
      )
    },
    currentTitle: function() {
      if (Array.isArray(this.filterReports))
        return this.filterReports[this.filterReports.length - 1].report_title;
      else return this.filterReports.report_title;
    },

  },
  created() {
    // setTimeout(() => this.elementVisible = false, 40000)

    const json = "wp/v2/aboutsunshinetour";
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.about = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
    const jsons = "wp/v2/cosanctionedevents?randomadd=" +
          new Date().getTime();
    apiCall
      .wpInfo(jsons)
      .then(({ data }) => {
        this.co_sanctioned_events = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  methods: {
    checkboxValue: function(params) {
      this.check = params;
      console.log("this.check -- 572")
      console.log(this.check)
    },
    checkLoggedIn: function(params) {
      this.loggedInFan = params;
      console.log("this.loggedInFan -- 572")
      console.log(this.loggedInFan)
    },
    postFanselect() {
      axios
        .post (
          this.config.VUE_APP_FAN_MARKETING_URL + "tour=" + this.config.VUE_APP_FAN_DATA_BASE + "&action=select&access_key=" + this.config.VUE_APP_FAN_KEY + "&mc_host=" + this.config.VUE_APP_FAN_MC_HOST
        )
        .then((data) => {
          this.initalFanData = data.data.fields.field;
          if (typeof data.data.latest_terms == undefined) {
            this.showTermsBefore = true;
            this.latestTerms = data.data.latest_terms.document;
            if (this.latestTerms !== '') {
              this.latestTermsID =  data.data.latest_terms.document.id; 
            }
          } else {
            console.log('false')
            this.showTermsBefore = false;
          }
          for (const i of this.initalFanData) {
            if (i.extra_info.options) {
              const opts = i.extra_info.options.split(',');
              const optsArr = [];

              for (const j of opts) {
                const kvp = j.split('|~');

                optsArr.push({'code': kvp[0], 'desc': kvp[1]});
              }

              this.selectOpts[i.table_name + '-' + i.name] = optsArr;
            }
          }
          console.log(this.initalFanData)
          console.log('initalFanData')
        })
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
      if (this.scrollPosition === 0) {
        this.fixedHead = false
      } else {
        this.fixedHead = true
      }
    },
    scroll() {
      var score = document.getElementById("score");
      score.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    toggleShow() {
      this.show = !this.show;
    },
    onSubmit: function(user, password) {
      user = this.form.user
      password = this.form.password
      socketServe.openSocket(user, password)
      this.loggedIn(), this.showAlert(), this.$bvModal.hide('modal-1')
    },
    loggedIn(){
      setTimeout( () =>{
      const playerName = localStorage.getItem("playerName", "")
      const playerID = localStorage.getItem("playerID", "")
      const status = localStorage.getItem("status", "")
      this.log = {'playerName': playerName,'playerID': playerID,'status': status}
      this.$emit("log", { log: this.log })
      }, 5000);
      
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    signOut(){
      localStorage.playerName = null;
      localStorage.playerID = null;
      localStorage.password = null;
      localStorage.status = null;
      this.log = {playerName:null,playerID:null,status:null};
      this.$emit("log", { log: this.log })
    },
    fansignOut() {
      // localStorage.clear();
      window.location.href = "/"
    }
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_APIFULL_URL +
          "tmticx?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.seasons = response.data.tm_params.season_code;
        this.course = response.data.code;
        this.reports = response.data.reports.reports_entry;
        this.apiClass = response.data.class_api;
        this.reg_allow_reg = response.data.tm_params.reg_allow_reg;
        this.multiTourns = response.data.multi_tourns;
        this.$emit("apiClass", { apiClass: this.apiClass });
        this.$emit("season", { season: this.seasons });
        this.$emit("course", { course: this.course });
        this.$emit("multiTourns", { multiTourns: this.multiTourns });
        this.$emit("currentReport", { currentReport: this.currentReport });
        this.$emit("currentTitle", { currentTitle: this.currentTitle });
      });
      window.addEventListener('scroll', this.updateScroll);
      this.$on('Authorisied', this.loginAuth); 
  },

};
</script>

<style scoped>
div#modal-lg___BV_modal_body_ {
    padding: 0;
}
.outerFromSign {
  display: inline-flex;
  width: 100%;
}
::v-deep div#modal-lg___BV_modal_body{
  padding: 0px;
}
h2.FanTitleSecond {
  font-size: 25px;
  font-weight: bold;
}
h2.FanTitle {
  font-size: 25px;
  font-weight: 200;
}
::v-deep .FanZoneSignUp-title {
  background-color: rgb(33 66 50);
  color: #fff;
}
::v-deep .FanZoneSignIn-title {
  background-color: #f2af15;
  color: #fff;
}
::v-deep .inputFontSize {
  font-size: 13px!important;
}
::v-deep .formFont {
  font-size: 13px!important;
  color:#6C6C6C;
}
.fanPsize {
  font-size: 13px;
  color: #6C6C6C;
}
button.btn.SignUp.btn-primary {
  width: 100%;
  background-color: rgb(33 66 50);
  border-color:rgb(33 66 50);
}
button.btn.SignIn.btn-primary {
  width: 100%;
  background-color: #f2af15;
  border-color: #f2af15;
  position: relative;
  bottom: -20px;
}
.modal-dialog.modal-md.modal-dialog-centered {
  max-width: 800px;
}
::v-deep header#modal-2___BV_modal_header_ {
  display: none!important;
}
.coSantioned {
  color: #fff;
  font-size: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.fourthCourse-logo {
  width: 210px;
  margin-top: 25px!important;
  /* margin-top: 50px!important; */
  text-align: center;
  margin: auto;
  display: block;
}
img.thirdCourse-logo {
  margin-top: 45px!important;
  width: 170px;
  text-align: center;
  margin: auto;
  display: block;
}
.courseLogo-co {
  width: auto;
  text-align: center;
  margin: auto;
  display: block;
  margin-top: 35px;
  height: 60px;
}
.backImage-Co {
  background-repeat: no-repeat;
  background-size: cover;
  height: 130px; 
  background-color: rgba(32 66 50/70%);
  background-blend-mode: multiply;
}
li.nav-item.BuyTickets > a.nav-link.router-link-exact-active.router-link-active {
  color: #fff!important;
}
span.signInplayer > li > a:hover {
  color: #fff!important;
}
li.nav-item.BuyTickets:hover > a {
  background-image: none!important;
}
.buyTicketsBlock:hover {
  background-color: #027741;
}
i.nav-item.BuyTickets {
  border-bottom: 0px;
}
.buyTicketsBlock {
  background: #f2af15 0% 0% no-repeat padding-box;
  border-radius: 0px;
  opacity: 1;
  top: 23px;
  left: 891px;
  width: 151px;
  height: 40px;
  text-align: center;
  font-size: 12px;
  margin-left: 50px;
}
li.nav-item.BuyTickets {
  margin-top: 3px;
  margin-bottom: 3px;
  border-bottom: 0px;
}
::v-deep button.close.text-dark {
  color: #fff!important;
  font-weight: bold!important;
  font-size: 45pt!important;
  opacity: 1;
  margin-left: -15px;
  margin-top: 8px;
}
::v-deep .b-sidebar.b-sidebar-right {
  left: auto;
  right: 0;
  background: #006737!important;
  height: 100%;
}
::v-deep .b-sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  margin: 0;
  outline: 0;
  transform: translateX(0);
}
button.btn.logIN.btn-primary {
  background-color: #006737;
  border-color: #006737;
}
button.btn.logIN.btn-primary:hover {
  background-color: rgb(33 66 50);
  border-color: rgb(33 66 50);
}
.AlertNot {
  position: absolute;
  top: 60%;
  left: 0;
  right: 0;
  width: 300px;
  margin: auto;
  text-align: center;
  z-index: 999;
}
::v-deep span.signInplayer > li > a:hover {
  background-image: none!important;
}
::v-deep li.nav-item.BuyTickets > a {
  color: #fff!important;
}
::v-deep .playerSignin.playerSignInSroll > span.signInplayer > li > a:hover{
  color: #fff!important;
}
.thirdRow {
  /* margin-top: -54px; */
}
.fourthRow {
  /* margin-top: -110px; */
  /* margin-top: -56px; */
}
button.navbar-toggler.btnWidth.collapsed {
  width: 91px;
}
button.navbar-toggler.btnWidth.not-collapsed {
  width: 91px;
}
.playerSignInSroll {
  /* background: #027741!important; */
}
.playerSignInSroll:hover{
  /* background: #F2AF14!important; */
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: transparent;
}
img.tourImageNav {
  width: 150px;
  text-align: center;
  margin: auto;
  display: block;
  margin-top: 10px;
}
a.sstNOunderliner:hover {
  text-decoration: none;
}
.menu.DesktopMenu.fixedHead {
  position: fixed;
  width: 100%;
  background: rgb(33 66 50) !important;
  z-index: 99999;
  top: 0;
}
li.nav-item {
  border-bottom: 1px solid #027741;
  margin-bottom: 10px;
  margin-top: 10px;
}
::v-deep a.nav-link:hover {
  color: #F2AF14!important;
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/Chevron_right_Yellow.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 6px;
}
.menuLeftTab {
  margin-left: initial!important;
  width: 17%;
  font-size: 13px;
}
.menuDrop {
  background-color: #006737;
}
.containerMenuBlock {
  padding-top: 40px;
  padding-bottom: 50px;
}
p.sunshineTitle {
  margin-bottom: 0px;
  text-align: center;
  color: #fff;
  font-size: 13px;
  margin-top: 4px;
}
p.sunshineTour {
  margin-bottom: 0px;
  text-align: center;
  color: #fff;
  font-weight: 600;
}
.RightMenuBlock {
  display: inline-flex;
}
.nopadNav {
  padding: 0rem 0rem;
}
.footerLogo {
  width: 100px;
  margin: auto;
  display: block;
}
.bigEasyback {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2024/06/Easy_BGgrey.png);
  padding-top: 30px;
  padding-bottom: 30px;
  background-size: cover;
  transition: transform .2s;
  background-repeat: no-repeat;
}
.ladiesTourback {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/LAdies_BG.png);
  padding-top: 30px;
  padding-bottom: 30px;
  background-size: cover;
  transition: transform .2s;
  background-repeat: no-repeat;
}
.seniorback {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/Senior_BG.png);
  padding-top: 30px;
  padding-bottom: 30px;
  background-size: cover;
  transition: transform .2s;
  background-repeat: no-repeat;
}

.bigEasyback:hover {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2024/06/Easy_BGgrey.png);
  padding-top: 30px;
  padding-bottom: 30px;
  background-size: cover;
  transform: scale(0.995);
  background-repeat: no-repeat;
}
.ladiesTourback:hover {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/LAdies_BG.png);
  padding-top: 30px;
  padding-bottom: 30px;
  background-size: cover;
  transform: scale(0.995);
  background-repeat: no-repeat;
}
.seniorback:hover {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/Senior_BG.png);
  padding-top: 30px;
  padding-bottom: 30px;
  background-size: cover;
  transform: scale(0.995);
  background-repeat: no-repeat;
}
.ourTours {
  color: #fff;
  font-size: 13px;
  position: relative;
  right: -14px;
}
.signInplayer {
  color: #FFFFFF;
  font-size: 12px;
  top: -6px;
  position: relative;
}
.signInplayer >li.nav-item {
  border-bottom: 0px solid #027741;
}
.playerSignin {
  background: #006737 0% 0% no-repeat padding-box;
  border-radius: 0px;
  opacity: 1;
  top: 23px;
  left: 891px;
  width: 151px;
  height: 40px;
  text-align: center;
  /* margin-left: 100px;
  margin-right: 100px; */
  margin-left: 50px;
  margin-right: 20px;
}
.playerSignin:hover {
  background: #f2af15 0% 0% no-repeat padding-box;
  border-radius: 0px;
  opacity: 1;
  top: 23px;
  left: 891px;
  width: 151px;
  height: 40px;
  text-align: center;
  /* margin-left: 100px;
  margin-right: 100px; */
  margin-left: 50px;
  margin-right: 20px;
}
.menuWidth {
  width: auto;
  height: 22px;
}
.menuWidthTour {
  width: 15px;
  height: 15px;
}
span.logosMenuMob {
  display: flex;
}
::v-deep a.nav-link.router-link-exact-active.router-link-active {
  color: #efae16!important;
}
.lock {
  margin-left: 10px;
}
.loginPlayer > a {
  padding-right: 14px;
  display: inline-table;
  font-size: 7pt;
  padding-top: 22px !important;
  padding-bottom: 8px;
  border-radius: 0 0 10px 10px;
  background-color: rgb(33 66 50) !important;
  text-align: center;
  padding-left: 15px !important;
}
.topLevel > ul > li > a {
  padding: 0.5rem 0.5rem;
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.covid {
  color: #ffffff;
  background-color: #cc0000;
  text-align: center;
  padding: 20px;
}
.covid > .row > .col > h4 {
  color: #ffffff;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
}
.covid > .row > .col > p {
  margin: 0;
  font-size: 14px;
  padding-bottom: 20px;
}
.covid > .row > .col > a {
  padding: 10px 30px;
  border: 1px solid #fff;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9em;
  background-color: transparent;
  color: white !important;
}
#covidClose {
  display: block;
  opacity: 1;
  position: absolute;
  right: 1px;
  top: -20px;
  transform: scaleY(0.8);
  z-index: 1;
}
#covidClose > button {
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
  font-size: 40px;
  padding: 10px;
  display: block;
  line-height: 0.8;
  color: white !important;
}
.moreLogos {
  width: 80px;
}
.siteLogo {
  /* width: 200px;
  position: inherit;
  top: 16px;
  right: 80px; */
  width: 220px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.siteLogoRight {
  max-width: 60px;
  float: right;
  min-width: 60px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.headLink > .nav-link {
  color: #fff;
  font-size: 8pt !important;
}

.menuLink > .nav-link {
  color: white !important;
  font-weight: 500;
  font-size: 0.775rem;
}

::v-deep .dropdown-item {
  color: white !important;
  font-weight: 500;
}

::v-deep .dropdown-item:hover {
  color: #00b588 !important;
  background-color: #006837;
}

::v-deep .dropdown-menu {
  background-color: #006837;
}

::v-deep .dropdown-toggle::after {
  display: none;
}
::v-deep .dropdown-toggle {
  font-size: 14px;
}
::v-deep .dropdown-toggle > span {
  color: white !important;
  font-weight: 500;
}
.menuLink > .nav-link:hover {
  color: rgb(242, 175, 20) !important;
}
.work {
  margin: -170px -100px 0 150px;
  color: white;
}
.subImg {
  margin-left: -50px;
  margin-top: -50px;
}
.link {
  color: rgb(255, 255, 255);
  text-transform: capitalize;
  max-width: 180px;
  font-weight: 300;
  text-align: center;
  background: rgb(0, 67, 62);
  margin: -4px 0px;
  padding: 10px;
}
.subscribe {
  width: 140%;
  height: 350px;
  background-color: #006837 !important;
}
.icon {
  padding-bottom: 7px;
  color: white;
}
.sIcon {
  background-color: #006837;
  color: white !important;
}
#topNav {
  /* background-image: url('https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png'); */
  background-color: rgb(33 66 50) !important;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

#bottomNav {
  /* background-image: url('https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png'); */
  background-color: rgb(33 66 50) !important;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}
::v-deep .dropdown > a {
  display: none;
}
.footLink > a {
  font-size: 9pt;
}
li {
  list-style-type: none;
}
li > a {
  color: white !important;
}
.mobShow {
  display: none;
}
.aboutInfo {
  background-color: rgb(33 66 50) !important;
  height: 350px;
}
.aboutBtn {
  display: block;
  background: #f2af15;
  border: none;
  color: #fff !important;
  border-radius: 0px;
  padding: 5px 20px 5px 20px;
  width: 200px;
  text-align: center;
  margin-top: 25px;
  float: right;
}
.aboutBtn:hover {
  background-color: #006837;
}
.aboutText {
  color: #fff;
}
.aboutTitle {
  font-size: 24px;
  padding-bottom: 0.3em;
  line-height: 1.25;
  color: #f2af15;
}
@media only screen and (min-width: 1201px) {
  .MobileMenu {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .signInplayer {
    color: #FFFFFF;
    font-size: 13px;
    top: 4px;
    position: relative;
  }
  .DesktopMenu {
    display: none;
  }
  .playerSignin {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    text-align: left;
    margin-bottom: 20px;
  }
  .menuLeftTab {
    margin-left: initial!important;
    width: 100%;
  }
  .RightMenuBlock > button {
    background-color: transparent;
    border-color: transparent;
  }
}
@media only screen and (max-width: 1024px) {
  #nav-collapse {
    padding-left: 0;
  }
  .signInplayer {
    color: #FFFFFF;
    font-size: 13px;
    top: 5px;
    position: relative;
  }
}

@media only screen and (min-width: 991px) {
  .hideDeskLogos {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  .signInplayer {
    color: #FFFFFF;
    font-size: 13px;
    top: 6px;
    position: relative;
  }
  ::v-deep .nopadNav {
    padding: 1.5rem 0rem;
  }
  ::v-deep .siteLogo {
    width: 200px;
    position: absolute;
    top: 6px;
  }
  .paddTop {
    padding-top: 20px;
  }
  li.nav-item.loginPlayer {
    display: none;
  }
  ul.navbar-nav {
    display: block;
  }
  span.hideDeskLogos {
    display: block;
    padding-left: 25px;
  }
  span.logosMenuMob {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .coSantioned {
    color: #fff;
    font-size: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: none;
  }
  .menuDrop {
    background-color: #006737;
    margin-top: 25px;
  }
  .mobHide {
    display: none;
  }
  .mobShow {
    display: block;
    text-align: center;
  }
  .footLink > a {
    font-size: 16px;
  }
  .menuLink.mobShow {
    text-align: left;
  }
  .btn-group {
    position: relative;
    display: block;
  }

  .mobileMenu {
    position: absolute;
    padding: 0;
    background-color: #00433e;
    top: -30px;
    left: -140px;
    height: 100vh;
    width: 100vh;
  }
  .siteLogoMenu {
    width: 180px;
    padding: 20px 35px;
  }
  ::v-deep .dropdown-toggle {
    background-color: transparent;
    border: 0;
  }

  .menuLink {
    padding: 6px 26px;
  }

  ::v-deep .menuLink > button {
    font-size: 21px;
  }

  .menuLink > .nav-link {
    font-size: 18px;
  }
}
@media only screen and (max-width: 480px) {
  .lock {
    margin-left: 0px;
    margin-top: 5px;
  }
  .menuDrop {
    background-color: #006737;
    margin-top: 20px;
  }
  .playerSignin {
    width: 100%;
    margin-right: 30px;
  }
  .siteLogo {
    width: 170px!important;
    position: absolute;
    top: 6px;
  }
  .paddTop {
    padding-top: 20px;
  }
  span.logosMenuMob {
    display: none;
  }
  .aboutInfo {
    background-color: rgb(33 66 50) !important;
    height: auto;
  }
  .aboutBtn {
    display: block;
    background: #f2af15;
    border: none;
    color: #fff !important;
    border-radius: 0px;
    padding: 5px 20px 5px 20px;
    width: 100%;
    text-align: center;
    margin-top: 25px;
    float: right;
  }
  .greenNav {
    display: none;
  }

  #nav-collapse {
    padding-left: 0;
  }

  #logo {
    width: 90%;
  }
  .mobileMenu {
    top: -9px;
    left: -9px;
  }
}
</style>