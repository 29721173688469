<template>
  <div class="outer_container" style="overflow: scroll; overflow-y: hidden;">
    <div class="inner_container" style="width: 2800px">
      <div class="accordion__wrapper">
        <div class="service__table">
          <div
            class="table__cell"
            v-for="(tourn, index) in tournamentList"
            :key="index"
            :class="{ current: tourn.code === tm.code }"
            
          >
            <div class="date_title_block">
              <h3 class="cover_service__date">{{ tourn.dates }}</h3>
              <h3 class="cover_service__title">{{ tourn.short_name }}</h3>
            </div>
            <span
              class="service_text courseimage-home"
              :style="
                'background-image: url(' +
                  DEFIMAGESAMS3 
                  +
                  'sst'
                  +
                  '/'
                  +
                  'courses'
                  +
                  '/'
                  +
                  tm.tm_params.season_code +
                  '/' +
                  tourn.course_code +
                  '/' +
                  tourn.course_image_file_1 +
                  '); display: block; background-repeat: no-repeat; background-size: cover; height: -webkit-fill-available;'
              "
            >
              <div id="overMay"></div>
              <div class="service_logo">
                <b-img
                  class="cardlogo"
                  v-if="tourn.sponsor_logo.length > 0"
                  :src="tourn.sponsor_logo"
                ></b-img>
              </div>
              <h3 class="service__title">{{ tourn.short_name }}</h3>
              <h3 class="service__date">{{ tourn.dates }}</h3>
              <div class="row sliderInfo-space">
                <div class="col-md-6">
                  <p class="tourn-small-info">Rounds</p>
                  <p class="value-info-home" v-if="tourn.rounds.length > 0">
                    {{ tourn.rounds }}
                  </p>
                  <p class="value-info-home" v-else>
                    N/A
                  </p>
                </div>
                <div class="col-md-6">
                  <p class="tourn-small-info">Prize Fund</p>
                  <p
                    class="value-info-home"
                    v-if="tourn.total_prize_fund.length > 0"
                  >
                    {{ tourn.total_prize_fund }}
                  </p>
                  <p class="value-info-home" v-else>
                    N/A
                  </p>
                </div>
              </div>
              <a
                :href="'/tournament?id=' + tm.tm_params.season_code + '&code=' + tourn.code"
                class="tournInfoBtn btn tournBtn-home"
                >Tournament Information</a
              >
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <vueper-slides
      class="no-shadow tournSLider"
      :visible-slides="5"
      :slide-ratio="1 / 5"
      :dragging-distance="70">
      <vueper-slide 
        v-for="(tourn, i) in data.tournaments.tournaments_entry" 
        :key="i" 
        :title="tourn.short_name.toString()" 
        :content="tourn.dates.toString()"
        :image="'https://bigeasytour.co.za' + '/pmws-logos/courses/' + id + '/' + tourn.course_code + '/' + tourn.course_image_file_1"
      />
    </vueper-slides> -->
  </div>
</template>
<script>
// import apiCall from "@/services/api.js";
// import { VueperSlides, VueperSlide } from 'vueperslides'
// import 'vueperslides/dist/vueperslides.css'
export default {
  name: "upComingTours",
  props: ["config", "season", "tm", 'tournamentList', 'DEFIMAGESAMS3'],
  // components: { VueperSlides, VueperSlide },
  data() {
    return {
      // tm_params: [],
      // id: [],
      // data: [],
    };
  },
  methods: {
    scrollToElement() {
      let content = document.querySelector(".current");
      content.scrollLeft = 3000;
    },
  },
  async mounted() {
    // try {
    //   let first_call = await apiCall.tmParamsYear(this.tm.tm_params.season_code);
    //   this.data = first_call.data;
    //   this.loading = false;
    // } catch (error) {
    //   console.log("error in await calls");
    //   console.log(error);
    //   throw error;
    // }
    setTimeout(() => {
      this.scrollToElement();
    }, 1700);
  },
};
</script>

<style scoped>

/* viperSlides */
::v-deep .vueperslide.vueperslide--visible {
  background-image: linear-gradient(#006837, #003b1f);
  border-left: 1px solid grey;
  transform: skew(0deg);
  transition-duration: 2s;
}
::v-deep .vueperslide.vueperslide--visible:hover {
  background-image: linear-gradient(pink, pink);
  border-left: 1px solid grey;
  width: 450px!important;
}
::v-deep .vueperslide__title {
  color: #f2af15;
  font-weight: bold;
}
::v-deep .vueperslide__content {
  color: #fff;
  font-weight: bold;
}
/* End of viperSlides */

.accordionWrapper {
  height: 400px;
  width: 100%;
  overflow: scroll;
}
#overMay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
  cursor: pointer;
}
.current.table__cell {
  background-color: #003b1f;
  width: 500px;
  background-image: none;
  /*background-image: url('/wp-content/uploads/2021/03/1249570.jpg');*/
  border-bottom: 1px solid #003b1f;
  background-repeat: no-repeat;
  background-size: cover;
}
.current.table__cell > span.service_text {
  opacity: 1;
}
.current.table__cell > .date_title_block {
  display: none;
}
.current.table__cell > span > div > img {
  height: 80px;
  margin: auto;
  text-align: center;
  display: list-item;
  opacity: 1;
  margin-bottom: 20px;
  margin-top: 30px;
}
.current.table__cell > span > h3.service__title {
  /*color: rgb(33 66 50) !important;*/
  color: rgb(255 255 255) !important;
  margin-top: 0px;
  margin-bottom: 0px;
  opacity: 1;
  text-align: center;
  z-index: 9999;
  position: relative;
}
.current.table__cell > span > h3.service__date {
  opacity: 1;
  transition-delay: 300ms;
  text-align: center;
  color: #f2af15;
  margin-top: 10px;
  /* margin-bottom: 40px; */
  margin-bottom: 25px;
  z-index: 9999;
  position: relative;
}
.current.table__cell > span > .sliderInfo-space {
  opacity: 1;
  transition-delay: 300ms;
  /*background: white;*/
  width: 100%;
  margin-left: 0px;
  z-index: 9999;
  position: relative;
}
.current.table__cell > span > a.tournInfoBtn {
  opacity: 1;
  transition-delay: 300ms;
  width: 400px;
  z-index: 9999;
  position: relative;
}
.date_title_block {
  position: absolute;
  left: 0;
  right: 0;
  top: 30%;
}
.accordion__wrapper {
  height: 400px;
  width: 100%;
  overflow: scroll;
  /*background: blue;*/
}

.service__table {
  display: inline-flex;
  width: fit-content;
  height: 100%;
  justify-content: center;
}

.table__cell {
  /*transform: skew(-20deg);*/
  transform: skew(0deg);
  position: relative;
  /*display: flex;*/
  width: 300px;
  align-items: center;
  justify-content: center;
  transition: width 500ms cubic-bezier(0.4, 0, 0.2, 1);
  background-image: linear-gradient(#006837, #003b1f);
  border-left: 1px solid grey;
}
.table__cell:hover {
  width: 500px;
  /*background: white!important;*/
  /*background-image: url('/wp-content/uploads/2021/03/1249570.jpg');*/
  border-bottom: 1px solid #003b1f;
  background-repeat: no-repeat;
  background-size: cover;
}
.pointer-hover:hover {
  width: 500px;
  background: white !important;
  border-bottom: 1px solid #003b1f;
}
.table__cell .service__title {
  /*white-space: nowrap;*/
  transform: rotate(-0deg) translate(0, 0);
  text-align: center;
}
.table__cell:hover .service__title {
  /*color: rgb(33 66 50) !important;*/
  color: rgb(255 255 255) !important;
  margin-top: 0px;
  margin-bottom: 0px;
  z-index: 9999;
  position: relative;
}
.pointer-hover:hover .service__title {
  color: rgb(33 66 50) !important;
  margin-top: 0px;
  margin-bottom: 0px;
}
.service__title {
  transform: skew(0deg);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0;
}
.cover_service__date {
  color: #f2af15;
  text-align: center;
  z-index: 9999;
  position: relative;
}
.cover_service__title {
  color: #fff;
  text-align: center;
  z-index: 9999;
  position: relative;
}
.table__cell:hover .cover_service__date {
  display: none;
}
.pointer-hover:hover .cover_service__date {
  display: none;
}
.table__cell:hover .cover_service__title {
  display: none;
}
.pointer-hover:hover .cover_service__title {
  display: none;
}
.table__cell:hover .service__title {
  opacity: 1;
  transition-delay: 300ms;
  text-align: center;
  color: #fff;
  /*background-color: rgb(255 255 255 / 30%);*/
  /*background-color: rgb(0 0 0 / 30%);*/
}
.pointer-hover:hover .service__title {
  opacity: 1;
  transition-delay: 300ms;
  text-align: center;
  color: #fff;
}
.service__date {
  transform: skew(0deg);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0;
  z-index: 9999;
  position: relative;
}
.table__cell:hover .service__date {
  opacity: 1;
  transition-delay: 300ms;
  text-align: center;
  color: #f2af15;
  margin-top: 10px;
  margin-bottom: 25px;
  /*background-color: rgb(0 0 0 / 30%);*/
}
.pointer-hoverpointer-hover:hover .service__date {
  opacity: 1;
  transition-delay: 300ms;
  text-align: center;
  color: #f2af15;
  margin-top: 10px;
  margin-bottom: 25px;
}
.table__cell .service_text {
  transform: skew(0deg);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0;
}
.table__cell:hover .service_text {
  opacity: 1;
  transition-delay: 300ms;
}
.pointer-hover:hover .service_text {
  opacity: 1;
  transition-delay: 300ms;
}
.table__cell .sliderInfo-space {
  transform: skew(0deg);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0;
}
.table__cell:hover .sliderInfo-space {
  opacity: 1;
  transition-delay: 300ms;
  width: 100%;
  margin-left: 0px;
  z-index: 9999;
  position: relative;
  /*background-color: rgb(255 255 255 / 30%);*/
  /*background-color: rgb(0 0 0 / 30%);*/
}
.pointer-hover:hover .sliderInfo-space {
  opacity: 1;
  transition-delay: 300ms;
  background: white;
  width: 100%;
  margin-left: 0px;
}
.table__cell img.cardlogo {
  transform: skew(0deg);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0;
  position: relative;
  z-index: 9999;
  height: 80px;
  margin: auto;
  text-align: center;
  display: list-item;
  opacity: 1;
  margin-bottom: 20px;
  margin-top: 30px;
}
.table__cell:hover img.cardlogo {
  height: 80px;
  margin: auto;
  text-align: center;
  display: list-item;
  opacity: 1;
  margin-bottom: 20px;
  margin-top: 30px;
}
.pointer-hover:hover img.cardlogo {
  /*width: 200px;*/
  height: 120px;
  margin: auto;
  text-align: center;
  display: list-item;
  opacity: 1;
  margin-bottom: 20px;
  margin-top: 20px;
}
.table__cell .tournInfoBtn {
  transform: skew(0deg);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0;
}
.table__cell:hover .tournInfoBtn {
  opacity: 1;
  transition-delay: 300ms;
  width: 400px;
  z-index: 9999;
  position: relative;
}
.pointer-hover:hover .tournInfoBtn {
  opacity: 1;
  transition-delay: 300ms;
  width: 400px;
}
.service_logo {
  height: 163px;
  overflow: hidden;
  z-index: 9999;
  position: relative;
}
p.tourn-small-info {
  color: #fff;
  margin-bottom: 0;
  text-align: center;
  font-size: 10pt;
}
p.value-info-home {
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
  text-align: center;
}
.tournBtn-home {
  text-align: center;
  display: block;
  margin: auto;
  background: #f2af15;
  border: none;
  color: #fff !important;
  border-radius: 0;
  padding: 5px 20px;
  width: 100%;
}
.tournBtn-home:hover {
  text-align: center;
  display: block;
  margin: auto;
  background: #214232;
  border: none;
  color: #fff !important;
  border-radius: 0;
  padding: 5px 20px;
  width: 100%;
}
.sliderInfo-space {
  margin-bottom: 20px;
}
h3 {
  font-size: 18px;
  line-height: 25px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  color: #24292e;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 15px;
}
</style>